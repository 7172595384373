import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

// components
import SEO from 'src/components/seo';
import { SectionHeader } from 'src/components/Photography';
import { Navbar, Banner, Footer, CinematographyImage } from 'src/components/common';

Modal.setAppElement(`#___gatsby`);

const PlayIcon = () => (
  <svg x="0px" y="0px" viewBox="0 0 477.867 477.867" width={64} height={64}>
    <path
      fill="#FFC001"
      d="M238.933 0C106.974 0 0 106.974 0 238.933s106.974 238.933 238.933 238.933 238.933-106.974 238.933-238.933C477.726 107.033 370.834.141 238.933 0zm100.624 246.546a17.068 17.068 0 01-7.662 7.662v.085L195.362 322.56c-8.432 4.213-18.682.794-22.896-7.638a17.061 17.061 0 01-1.8-7.722V170.667c-.004-9.426 7.633-17.07 17.059-17.075a17.068 17.068 0 017.637 1.8l136.533 68.267c8.436 4.204 11.867 14.451 7.662 22.887z"
    />
  </svg>
);

const videoSet = [
  {
    videoId: 'https://www.youtube.com/embed/69MO7yU0d70?autoplay=1',
    companyName: 'Beyonce',
    subText: 'Black Is King',
    imageURL: 'beyonce',
    message:
      'Black is King is a visual album of The Lion King: The Gift. Beyoncé voiced Nala, whose name means "gift" in Swahili. We were commissioned as the cinematographers for all scenes in Ghana for this film. Now streaming on Disney+',
  },

  {
    videoId: 'https://player.vimeo.com/video/399218897?title=0&byline=0',
    companyName: 'Apple',
    subText: 'A Great Day In Accra',
    imageURL: 'apple',
    message:
      'A Great Day In Accra is an Iconic project that highlights the birth and evolution of hiplife music genre in Ghana. In line with our mission and values, we collaborated with Apple and Mass Appeal (by Nas) to document Prince Gyasi’s powerful perspective of the evolution of Hiplife in Ghana.',
  },
  {
    videoId: 'https://player.vimeo.com/video/310395621?title=0&byline=0',
    companyName: 'Innovative MF',
    imageURL: 'innovative',
    subText: 'Where The Sun Never Sets On Happiness',
    message:
      "The people of the Northern Region are rich in culture, they understand family and unity and more importantly they seamlessly fuse happiness and work. In this documentary for Innovative Microfinance we explore how the 'Sun Never Sets On Happiness' in Tamale and Bolgatanga.",
  },
  {
    videoId: 'https://player.vimeo.com/video/375398495?title=0&byline=0',
    videoId: '',
    companyName: 'Vlisco',
    imageURL: 'vlisco',
    subText: 'Beneath The Fabric',
    message:
      "In a research project with Vlisco&co we captured the stories Vlisco fabric weaved through Ghanaian history and culture. This research project explored how this piece of history from the older generation can be carried on to the younger generation. 'Beneath The Fabric' is how we creatively captured this research project which spanned over 10days. Watch full Video here",
  },
  {
    videoId: 'https://player.vimeo.com/video/310407839?title=0&byline=0',
    companyName: 'Tony’s Chocolonely',
    imageURL: "tony's-chocolonely",
    subText: 'Reframed - Cocoa & Colour',
    message: `Journeying through Eastern Region - Ghana and Ivory Coast, 
   in a collaborated with New York renowned photographer, Joshua Kissi to document the lives of cocoa farmers contributing significantly to the global cocoa supply chain. Tony’s Chocolonely strives for a equal supply chain in the cocoa industry such that cocoa farmers are treated with respect and given what they deserve.`,
  },
  {
    videoId: 'https://player.vimeo.com/video/310400496?title=0&byline=0',
    companyName: 'Unilever x Chelsea FC',
    imageURL: 'rexonaxchelsea',
    subText: 'Be The Next Champ',
    message: `With the aim of discovering untapped football talent, we embarked on a month long road trip across all 10 regions of Ghana in collaboration with Rexona and Chelsea Football Club. The winners were flown to England to play against Chelsea team alongside other teammates across Africa. Watch full series here `,
  },
  {
    videoId: 'https://player.vimeo.com/video/396290865?title=0&byline=0',
    companyName: 'British Council',
    imageURL: 'british-council',
    subText: '#EachForEqual',
    message: `In honor of International Women’s day, Beautiful Stories Studios collaborated with Hashtag Creative Shop on this project for British Council. `,
  },
  {
    videoId: 'https://player.vimeo.com/video/396290865?title=0&byline=0',
    companyName: 'Rajwa Company x Conan O’brien',
    imageURL: 'conan',
    subText: 'The Behind The Behind The Scenes.',
    message: `
  Conan O’brien’s visit to Ghana Episode of “Conan Without Borders” as seen on Netflix was a heartfelt journey enjoyed by many. We captured the “behind the behind the scenes” of how producer Lady May pulled off this task.`,
  },
  {
    videoId: 'https://player.vimeo.com/video/396129770?title=0&byline=0',
    companyName: 'QOAST2QOAST',
    imageURL: 'identity',
    subText: 'Identity - A New Wave of African Storytellers',
    message: `Qoast2Qoast is a collective based on connecting creatives between Africa and the diaspora to continue to shift the narrative. This documentary celebrates the emergence of a new generation of African Storytellers across the world.`,
  },
  {
    videoId: 'https://player.vimeo.com/video/315224023?title=0&byline=0',
    companyName: 'To Be Ghanaian In London',
    imageURL: 'london',
    subText: '',
    message: `This documentary celebrates the vastness of Ghanaian identity. Subliminally debunking the idea that being Ghanaian has to be tied to one thing but rather highlighting what it means to be Ghanaian from a Diasporan perspective. This is a personal piece`,
  },
];

const CinematographyPage = () => {
  const [showModal, toggleModal] = useReducer((toggled) => !toggled, false);

  const [selectedVideo, setSelectedVideo] = useState(null);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '70%',
      height: '70%',
    },
  };

  return (
    <>
      <SEO title="Cinematography" />

      <Navbar dark={true} />

      <div
        className="container-lg"
        style={{
          marginTop: '100px',
        }}
      >
        <SectionHeader sectionName="Cinematography" />
      </div>

      {videoSet.map((item, index) => {
        const newIndex = index + 1;
        const imageURl = item.imageURL || 'Conan';

        if (newIndex % 2 === 0) {
          return (
            <VideoContainer className="reverse">
              <div
                className="video-container"
                onClick={() => {
                  setSelectedVideo(item.videoId);
                  toggleModal();
                }}
              >
                <CinematographyImage width="100%" name={imageURl} />
                <div className="playIcon">
                  <PlayIcon />
                </div>
              </div>
              <div className="content bg-grey">
                <div className="header">{item.companyName}</div>
                <div className="subText">{item.subText}</div>
                <div className="message">{item.message}</div>
              </div>
            </VideoContainer>
          );
        }

        return (
          <VideoContainer>
            <div className="content bg-white">
              <div className="header">{item.companyName}</div>
              <div className="subText">{item.subText}</div>
              <div className="message">{item.message}</div>
            </div>

            <div
              className="video-container"
              onClick={() => {
                setSelectedVideo(item.videoId);
                toggleModal();
              }}
            >
              <CinematographyImage width="100%" name={imageURl} />
              <div className="playIcon">
                <PlayIcon />
              </div>
            </div>
          </VideoContainer>
        );
      })}
      <Banner />
      <Footer />

      <Modal
        isOpen={showModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={toggleModal}
        style={customStyles}
      >
        <iframe
          id="ytplayer"
          type="text/html"
          style={{
            width: '100%',
            height: '100%',
          }}
          src={selectedVideo}
          frameborder="0"
        ></iframe>
      </Modal>
    </>
  );
};

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap-reverse;
    margin-bottom: 30px;
  }

  .bg-white {
    background-color: white;
  }

  .bg-grey {
    background-color: #f8f8f8;

    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
    }
  }

  .content {
    flex-basis: 40%;
    padding: 20px 40px;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;

    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
      padding: 15px 20px;
    }

    .header {
      line-height: 50px;
      font-size: 2rem;
    }

    .subText {
      margin-top: 10px;
      margin-bottom: 15px;
      color: ${({ theme }) => theme.colors.greyPrimary};
    }
  }

  .video-container {
    flex-basis: 60%;
    position: relative;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
    }

    .playIcon {
      position: absolute;
      left: 50%;
      margin-left: -50px;
      top: 50%;
      color: white;

      @media only screen and (max-width: 600px) {
        transform: scale(0.8);
        top: 35%;
        margin-left: -20px;
      }
    }
  }
`;

export default CinematographyPage;
